import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-not-found">
              <b>404</b>
              <span>الصفحة التي تبحث عنها غير موجودة</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
